import axios from 'axios'
import { System } from '../../pages/Instances/Types'
import { OrganizationsProps } from 'pages/Organizations/Types'
import { toast } from 'react-toastify'
import { config } from '../../commonResources/config'
import { errorHandler, showErrorToast } from '../error/error'
import { parseSort } from '../../services/app/app'

const baseUrl = `${config.hostUrl}/api-v1`

export const getAllOrganizations = async (
  accessToken: string | null,
  page: number,
  size: number,
  sort?: any
) => {
  try {
    return await axios.get(
      `${baseUrl}/${System.Organization}?page=${page}&size=${size}&sort=${sort}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
  } catch (error: any) {
    if (error?.response?.status === 401) {
      errorHandler('Unauthorized', error)
    }
    showErrorToast(error, 'Error fetching organizations')
  }
}

export const getOrganizationById = async (accessToken: any, id: string) => {
  return await axios.get(`${baseUrl}/${System.Organization}/${id}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  })
}

export const getSapphireOrganizationsWithOptions = async (
  accessToken: string | null,
  queryParams: any = [],
  page: any = {},
  sort: any = []
) => {
  try {
    const queryString = queryParams
      .filter((q) => q.value)
      .map((q) => {
        const field =
          q.field === 'organization.name'
            ? 'organizationName'
            : q.field === 'instance.hostname'
              ? 'instanceName'
              : q.field
        return `${field}=${q.value}`
      })
      .join('&')

    const pageString = `page=${page.page || 0}&size=${page.take || 20}`
    const sortString = sort.length ? `&sort=${parseSort(sort)}` : ''

    const url = `${baseUrl}/${System.Organization}?${queryString}&${pageString}${sortString}`

    return await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
  } catch (error) {
    console.error('Error:', error)
    showErrorToast(error, 'Error fetching organizations')
  }
}

export const createOrganization = async (
  accessToken: string,
  newOrganizations: OrganizationsProps.OrganizationPUT
) => {
  try {
    const response = await axios.post(
      `${baseUrl}/${System.Organization}`,
      newOrganizations,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    toast.success('Successfully created organization', {
      toastId: 'success',
    })
    return response
  } catch (error) {
    console.error('Error:', error)
    showErrorToast(error, 'Error creating organization')
  }
}

export const deleteOrganizationById = async (
  accessToken: string | null,
  id: string
) => {
  try {
    const response = await axios.delete(
      `${baseUrl}/${System.Organization}/${id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    toast.success('Successfully deleted organization', {
      toastId: 'success',
    })
    return response
  } catch (error) {
    console.error('Error:', error)
    showErrorToast(error, 'Error deleting organization')
  }
}

export const updateOrganizationById = async (
  accessToken: string | null,
  id: string,
  data
) => {
  try {
    const response = await axios.put(
      `${baseUrl}/${System.Organization}/${id}`,
      { ...data },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
    toast.success('Successfully updated organization', {
      toastId: 'success',
    })
    return response
  } catch (error) {
    console.error('Error with PUT request:', error)
    showErrorToast(error, 'Error updating organization')
  }
}
