import React, { useCallback, useEffect, useState } from 'react'
import { OrganizationsProps } from '../../Types'
import {
  createOrganization,
  updateOrganizationById,
} from '../../../../services/organizations/organizations'
import { getAllInstances } from '../../../../services/instances/instances'
import { Instance } from '../../../Instances/Types'
import { useGlobalContext } from '../../../../commonResources/GlobalContextProvider'
import { showErrorToast } from '../../../../services/error/error'

export const useOrganizationsDialogHook = (
  props: any
): OrganizationsProps.DialogType => {
  const globalContext = useGlobalContext()
  const organizationContext = globalContext?.organizationContext || {}
  const setOrganizationContext =
    globalContext?.setOrganizationContext || (() => {})
  const [changes, setChanges] = useState<boolean>(false)
  const [instanceList, setInstanceList] = useState<Instance[]>([])
  const handleChange = useCallback(
    (field: string, event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      setChanges(value.length > 0)

      if (props.isEditing) {
        updateOrganizationContext(field, value)
      } else {
        createNewOrganizationContext(field, value)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organizationContext, props.isEditing]
  )

  const updateOrganizationContext = (field: string, value: string) => {
    setOrganizationContext({
      itemsSelected: organizationContext?.itemsSelected?.map((item) => ({
        ...item,
        [field]: value,
      })),
    })
  }

  const createNewOrganizationContext = (
    field: string,
    value: string | undefined
  ) => {
    setOrganizationContext({
      ...organizationContext,
      newOrganization: {
        ...organizationContext?.newOrganization,
        [field]: value,
      },
    })
  }

  const resetState = () => {
    setOrganizationContext({
      itemsSelected: [],
      newOrganization: {
        name: '',
        caratOrgName: '',
        instance: undefined,
      },
    })
    props.clearCheckbox()
    props.toggleDialog()
    setChanges(false)
  }

  const handleClear = useCallback(
    (field: string) => {
      const resetFunctions: { [key: string]: () => void } = {
        name: () => createNewOrganizationContext('name', ''),
        caratOrgName: () => createNewOrganizationContext('caratOrgName', ''),
        instance: () => createNewOrganizationContext('instance', undefined),
      }

      setChanges(false)
      resetFunctions[field]?.()
    },
    [organizationContext]
  )

  const create = async () => {
    const { caratOrgName, name, instance } =
      organizationContext?.newOrganization || {}
    const payload = {
      caratOrgName,
      name,
      instanceId: instance?.id,
    }
    try {
      await createOrganization(props.accessToken, payload)
      props.toggleDialog()
      resetState()
      props.fetchData()
      setChanges(false)
    } catch (e: any) {
      showErrorToast(e, 'Error creating organization')
    }
  }

  const update = async () => {
    const { caratOrgName, name, instance } =
      organizationContext?.itemsSelected?.[0] || {}
    const payload = {
      caratOrgName,
      name,
      instanceId: instance?.id,
    }

    const showError = (field: string) => {
      showErrorToast(
        {
          response: {
            data: {
              title: 'Updating Organization',
              detail: `${field} is null or empty`,
            },
          },
        },
        'Error'
      )
    }

    if (!caratOrgName || !name || !instance?.id) {
      !caratOrgName && showError('caratOrgName')
      !name && showError('name')
      !instance?.id && showError('instance id')
      return
    }

    try {
      await updateOrganizationById(
        props.accessToken,
        organizationContext?.itemsSelected?.[0]?.id,
        payload
      )
      props.toggleDialog()
      resetState()
      props.fetchData()
      setChanges(false)
    } catch (e: any) {
      console.log('error: unable to update', e.message)
    }
  }

  const instanceData = async () => {
    try {
      const response = await getAllInstances(props.accessToken, 0, 1000, [
        'hostname,asc',
      ])
      const instances =
        response?.data?.content?.map(({ id, hostname }) => ({
          id,
          hostname,
        })) || []
      instances.push({ id: null, hostname: 'No Instance Specified' })
      setInstanceList(instances)
    } catch (e: any) {
      console.error('Error fetching instances:', e.message)
    }
  }

  useEffect(() => {
    if (props.showDialog) {
      instanceData()
    }
  }, [props.showDialog, organizationContext, setChanges])

  return {
    fetchData: props.fetchData,
    toggleDialog: props.toggleDialog,
    accessToken: props.accessToken,
    isEditing: props.isEditing,
    showDialog: props.showDialog,
    clearCheckbox: props.clearCheckbox,
    resetState,
    handleClear,
    create,
    handleChange,
    changes,
    update,
    setChanges,
    instanceList,
    instanceData,
  }
}
