import React from 'react'
import './Styles.scss'
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons'
import { useOrganizationsDialogHook } from './components/Dialog/hooks'
import DialogComponent from './components/Dialog'
import { OrganizationsProps } from './Types'
import { Checkbox } from '@progress/kendo-react-inputs/dist/npm/checkbox/Checkbox'
import { orgCheckboxChanged$ } from '../../services/app/app'
import ConfirmationComponent from '../../components/confirm'
import { useConfirmHook } from '../../components/confirm/hooks'
import SevenSignalGrid from '../../shared/7Signal/SevenSignalGrid'
import { SevenSignal } from '../../shared/7Signal/SevenSignalGrid/Types'
import { useGlobalContext } from '../../commonResources/GlobalContextProvider'
import {
  GridFilterChangeEvent,
  GridFilterOperators,
} from '@progress/kendo-react-grid'
import debounce from 'lodash.debounce'

export const clearCheckbox = (orgContext, setIsEditing) => {
  orgContext.itemsSelected = []
  setIsEditing(false)
}

const OrganizationsComponent = (props: OrganizationsProps.Component) => {
  const { organizationContext } = useGlobalContext()
  const dialogHookData = useOrganizationsDialogHook({
    toggleDialog: props.toggleDialog,
    fetchData: props.fetchData,
    accessToken: props.accessToken,
    isEditing: props?.isEditing,
    showDialog: props.showDialog,
    editData: props.editData,
    clearCheckbox: () => {
      orgCheckboxChanged$.next({
        isChecked: false,
        dataItems: props?.data,
      })
      props.setIsEditing(false)
    },
  })

  const confirmProps = useConfirmHook()

  const OrganizationCellRender = (td, cellProps) => {
    if (cellProps.field === 'selected') {
      return (
        <td>
          <Checkbox
            data-testid={'organizations-checkbox'}
            onClick={(e: any) => {
              props.onSelectionChange({
                ...cellProps,
                isChecked: e.target.checked,
                dataItems: props?.data,
              })
            }}
            value={organizationContext.itemsSelected.some(
              (item) => item.id === cellProps.dataItem.id
            )}
          />
        </td>
      )
    } else {
      return td
    }
  }

  const HeaderCellRender = (td, cellProps) => {
    if (cellProps.field === 'selected') {
      return <td></td>
    } else {
      return td
    }
  }

  const leftTableButtons: SevenSignal.TableButtons[] = [
    {
      title: 'Add',
      name: 'Add',
      onClick: props.addOrganization,
      className: 'organization-button',
      hasFontAwesomeIcon: true,
      disabled: false,
      icon: faPlus,
    },
    {
      title: 'Edit Changes',
      name: 'Edit',
      onClick: () => props.editOrganization(),
      className: 'organization-button',
      hasFontAwesomeIcon: true,
      disabled: props.shouldDisableButton(),
      icon: faEdit,
    },
    {
      title: 'Delete Changes',
      name: 'Delete',
      onClick: () => {
        props.handleConfirmDelete(props.editData)
        confirmProps.handleIsVisibleToggle()
      },
      className: 'organization-button',
      hasFontAwesomeIcon: true,
      disabled: props.shouldDisableButton(),
      icon: faTrash,
    },
  ]

  const columns: SevenSignal.Columns[] = [
    {
      field: props.SELECTED_FIELD,
      width: '50px',
      filterable: false,
      headerSelectionValue:
        props.data?.findIndex((item) => !props.idGetter(item)) === -1,
    },
    {
      field: 'name',
      title: 'Organization Name',
    },
    {
      field: 'caratOrgName',
      title: 'Carat Org Name',
    },
    {
      field: 'instance.hostname',
      title: 'Instance',
    },
  ]

  const filterOperators: GridFilterOperators = {
    text: [{ text: 'grid.filterContainsOperator', operator: 'contains' }],
    numeric: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
    date: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
    boolean: [{ text: 'grid.filterEqOperator', operator: 'eq' }],
  }

  return (
    <div className="page" data-testid={'organization-table'}>
      <h1 className="page-header__text">Sapphire Organizations</h1>
      <SevenSignalGrid
        customCellRender={OrganizationCellRender}
        customHeaderCellRender={HeaderCellRender}
        sortable={{
          allowUnsort: true,
          mode: 'single',
        }}
        sort={props.sort}
        skip={props.page.skip}
        take={props.page.take}
        total={props.pageSizeValue}
        handleSortChange={props.sortChange}
        handleDisable={props.shouldDisableButton()}
        data={props.data?.map((item) => ({
          ...item,
          [props.SELECTED_FIELD]: props.selectedState[props.idGetter(item)],
        }))}
        dataItemKey={'id'}
        rowHeight={50}
        selectedField={props.SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple',
        }}
        filterable={true}
        filterOperators={filterOperators}
        filter={props.filter}
        onFilterChange={debounce(
          (e: GridFilterChangeEvent) => props.handleFilter(e.filter),
          100
        )}
        handleFilter={props.handleFilter}
        leftTableButtons={leftTableButtons}
        columns={columns}
        idGetter={props.idGetter}
        onSelectionChange={props.onSelectionChange}
        onHeaderSelectionChange={props.onHeaderSelectionChange}
        onPageChange={props.pageChange}
      />
      {props.showDialog && <DialogComponent {...dialogHookData} />}
      {confirmProps.confirmIsVisible && (
        <ConfirmationComponent
          message={props.confirmDeleteMessage}
          onAbort={() => {
            confirmProps.handleIsVisibleToggle()
          }}
          onConfirm={() => {
            props.deleteOrganization()
            confirmProps.handleIsVisibleToggle()
          }}
        ></ConfirmationComponent>
      )}
    </div>
  )
}

export default OrganizationsComponent
