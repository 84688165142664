import { useCallback, useContext, useEffect, useState } from 'react'
import { getter, SortDescriptor } from '@progress/kendo-data-query'
import {
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridSortChangeEvent,
} from '@progress/kendo-react-grid'
import { AccessTokenContext } from '../../commonResources/AccessTokenContext'
import { OrganizationsProps } from './Types'
import {
  deleteOrganizationById,
  getAllOrganizations,
  getSapphireOrganizationsWithOptions,
} from '../../services/organizations/organizations'
import { asyncCurryHelper } from '../../services/app/app'
import { useGlobalContext } from '../../commonResources/GlobalContextProvider'
import { toast } from 'react-toastify'

export const setOrgSearchOptions = (field: String, newValue: any) => {
  const idx = OrganizationsProps.Helper.filters.findIndex(
    (item) => item.field === field
  )
  if (idx !== -1) OrganizationsProps.Helper.filters.splice(idx, 1, newValue)
  if (idx === -1) OrganizationsProps.Helper.filters.push(newValue)
}

export const useOrganizationsHook = (): OrganizationsProps.Component => {
  const accessToken = useContext<string | null>(AccessTokenContext)
  OrganizationsProps.Helper.hasAccessToken = accessToken !== null
  const globalContext = useGlobalContext()
  const organizationContext = globalContext?.organizationContext || {}
  const setOrganizationContext =
    globalContext?.setOrganizationContext || (() => {})
  const [data, setData] = useState<OrganizationsProps.Organization[]>([
    {
      id: '',
      name: '',
      instance: {
        id: '',
        hostname: '',
      },
      caratOrgName: '',
    },
  ])
  const [sort, setSort] = useState<Array<SortDescriptor>>([
    { field: 'name', dir: 'asc' },
  ])
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[]
  }>({})
  const [page, setPage] = useState<any>({ take: 20, page: 0 })
  const [pageSizeValue, setPageSizeValue] = useState<number>(0)
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isCheckboxSelected, setIsCheckboxSelected] = useState<any>({})
  const [editData, setEditData] = useState<OrganizationsProps.Organization>({
    id: '',
    name: '',
    instance: {
      id: '',
      hostname: '',
    },
    caratOrgName: '',
  })
  const [confirmDeleteMessage, setConfirmDeleteMessage] = useState('')
  const DATA_ITEM_KEY: string = 'id'
  const SELECTED_FIELD: string = 'selected'
  const idGetter = getter(DATA_ITEM_KEY)
  const [isSelected, setIsSelected] = useState<any>(null)
  const [filter, setFilter] = useState<any>({})
  const parseSort = (sort) => {
    return sort.map((item) => {
      const field =
        item.field === 'instance.hostname' ? 'instanceHostname' : item.field
      return `${field},${item.dir}`
    })
  }

  const handleFilter = async (e: any) => {
    setFilter(e)
    try {
      const fetchOrganizations = async (filters: any) => {
        const res = filters
          ? await asyncCurryHelper(getSapphireOrganizationsWithOptions)(
              accessToken,
              filters,
              page,
              sort
            )
          : await asyncCurryHelper(getAllOrganizations)(
              accessToken,
              page.page ?? 0,
              page.take ?? 20,
              parseSort(sort)
            )
        setData(res?.data?.content)
        setPageSizeValue(res?.data.pagination.total)
        setPage({
          page: page.page ?? 0,
          take: page.take ?? 20,
          skip: page.skip ?? 0,
        })
      }

      if (e === null) {
        await fetchOrganizations(null)
      } else if (e?.filters[0]?.value?.length >= 3) {
        await fetchOrganizations(e.filters)
      }
    } catch (error: any) {
      toast.error(error?.response?.response?.data?.detail)
    }
  }

  const sortChange = async (event: GridSortChangeEvent) => {
    let response
    event.sort.forEach((item) => {
      setOrgSearchOptions('sort', {
        field: 'sort',
        value: [
          {
            field: item.field,
            value: item.dir,
          },
        ],
      })
    })
    try {
      if (
        OrganizationsProps.Helper.filters.some((item) => item.field === 'page')
      ) {
        response = await asyncCurryHelper(getAllOrganizations)(
          accessToken,
          OrganizationsProps.Helper.filters.find(
            (item) => item.field === 'page'
          ).value,
          20,
          parseSort(event.sort)
        )
      } else {
        response = await asyncCurryHelper(getAllOrganizations)(
          accessToken,
          page.page,
          page.take,
          parseSort(event.sort)
        )
      }

      setData(response?.data.content)
      setSort(event.sort)
    } catch (error) {
      console.log('Unable to sort Sapphire Organizations:', error)
    }
  }
  const fetchData = async () => {
    try {
      if (OrganizationsProps.Helper.hasAccessToken) {
        const sortData = parseSort(sort)
        const response = await getAllOrganizations(
          accessToken,
          page.page,
          page.take,
          sortData
        )
        setData(response?.data.content)
        setPageSizeValue(response?.data.pagination.total)
      }
    } catch (error) {
      console.error('Error fetching organizations:', error)
    }
  }
  useEffect(() => {
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken])

  const addOrganization = () => {
    setIsEditing(false)
    setSelectedState({})
    setShowDialog(true)
  }

  const editOrganization = () => {
    setShowDialog(true)
    setIsEditing(true)
  }

  const deleteOrganization = async () => {
    const id = organizationContext?.itemsSelected?.[0]?.id
    await deleteOrganizationById(accessToken, id)
    fetchData()
    setSelectedState({})
  }

  const handleConfirmDelete = (objectData) => {
    let newDeleteMessage = 'Continue to delete ' + objectData.name + '?'
    setConfirmDeleteMessage(newDeleteMessage)
  }

  const pageChange = async (e) => {
    const page = Math.floor(e.page.skip / e.page.take)
    const pageSort = parseSort(sort)
    const filters = filter?.filters
    let response
    if (filter) {
      response = await asyncCurryHelper(getSapphireOrganizationsWithOptions)(
        accessToken,
        filters,
        { page, take: 20 },
        sort
      )
    } else {
      response = await getAllOrganizations(accessToken, page, 20, pageSort)
    }

    setData(response?.data?.content)
    setPageSizeValue(response?.data?.pagination.total)
    setPage({
      ...e.page,
      take: 20,
    })
    setOrgSearchOptions('page', {
      field: 'page',
      value: page,
    })
  }

  const toggleDialog = useCallback(() => {
    setShowDialog(!showDialog)
  }, [showDialog])

  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent | any) => {
      const { isChecked, dataItem } = event

      isChecked ? setIsSelected(dataItem) : setIsSelected(null)

      setOrganizationContext((prevContext) => ({
        ...prevContext,
        itemsSelected: isChecked
          ? [...prevContext.itemsSelected, dataItem]
          : prevContext.itemsSelected.filter((item) => item.id !== dataItem.id),
      }))
    },
    []
  )

  const hasTruthyValue = (obj) => {
    const array: any = Object.values(obj).filter(Boolean)
    return array.length === 1
  }

  const shouldDisableButton = () => {
    return organizationContext?.itemsSelected?.length === 0
  }

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target
      const checked = checkboxElement.checked
      const newSelectedState = {}

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked
      })

      OrganizationsProps.Helper.itemsToDelete = event.dataItems
      setSelectedState(newSelectedState)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {}, [toggleDialog, setIsSelected])
  return {
    onSelectChange: undefined,
    data,
    sort,
    sortChange,
    selectedState,
    SELECTED_FIELD,
    idGetter,
    addOrganization,
    deleteOrganization,
    handleConfirmDelete,
    confirmDeleteMessage,
    onSelectionChange,
    onHeaderSelectionChange,
    showDialog,
    toggleDialog,
    shouldDisableButton,
    accessToken,
    fetchData,
    pageSizeValue,
    pageChange,
    page,
    setData,
    setPageSizeValue,
    hasTruthyValue,
    parseSort,
    setSelectedState,
    editOrganization,
    isEditing,
    setIsEditing,
    setEditData,
    editData,
    isSelected,
    setIsSelected,
    isCheckboxSelected,
    setIsCheckboxSelected,
    filter,
    handleFilter,
    setFilter,
  }
}
